import React, { useState, useEffect, useLayoutEffect } from 'react'
import axios from "axios";
import StoreToken from "../../components/StoreToken";
import './VideoView.css'
import { defaultQuality, getLoginPage, globalLoader } from "../../script/util.js";
import 'video.js/dist/video-js.css';
import VideoPlayer from '../VideoPlayer.js';

const VideoView = () => {
  const [data, setData] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [bookingPK, setBookingPK] = useState()
  const defQuality = defaultQuality()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const { storeToLocal } = StoreToken();
  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  useEffect(()=>{
    var video_url = `${process.env.REACT_APP_STAG_URL}/api/work/${id}/`
    axios({
        url: video_url,
        method: "get",
        headers: {
            Authorization: "Token " + localStorage.getItem("eventGraphiaToken"),
        },
        })
        .then((response) => {
            setData(response.data);
            setBookingPK(response.data.booking_pk)
            for(let i = response.data.url_list.length; i>0; i--){
              if(response.data.url_list[i-1].quality===defQuality){
                setVideoUrl(response.data.url_list[i-1].url)
              }
            }
        })
        .catch((err) => {
            if (err.response.status == 401) {
            getLoginPage();
        }
    })
  },[])

  
  return !data ? (
    <div
      className='overlayLoader'
    >
      {globalLoader()}
    </div>
  ) : (
    <>
        <VideoPlayer data={data} bookingPK={bookingPK} id={id} videoUrl={videoUrl} />
    </>
  )
}

export default VideoView