import React, { useState } from 'react';
import { BiCopy } from "react-icons/bi";

const CopyButton = ({ value }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value.share_url);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
    };

    return (
        <div>
            <button style={{backgroundColor: value.is_disabled && "rgb(153, 153, 153", cursor:"pointer"}} disabled={value.is_disabled}  onClick={handleCopy} className="copy-link">

                {copied ? 'Copied!' : <BiCopy />}
            </button>
        </div>
    );
};

export default CopyButton;
