import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import "./Quotation.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import '../../components/copyModal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faChevronDown,
  faLink,
  faXmark,
  faArrowUp,
  faShareNodes,
  faCopy,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import StoreToken from "../../components/StoreToken";
import { getLoginPage } from "../../script/util.js";
import { quotation_string, isAdmin, getWhatsappShareUrl, globalLoader, isMobile } from "../../script/util";
import Popup from "../../components/Popup/Popup"
import copy from "clipboard-copy";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Helmet from 'react-helmet';
import wg_logo from './Quotation_images/wg_logo.png'
import Slider from '../../components/Slider/Slider.js'

const WGQuotation = ({ preview }) => {
  const divRef = useRef(null);
  const [data, setData] = useState();
  const [preventDownloadable, setPreventDownloadable] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [shareUrl, setShareUrl] = useState();
  const [showMore, setShowMore] = useState(false);
  const [addPopUp, setAddPopUp] = useState(false);
  const navigate = useNavigate();
  const [backTop, setBackTop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showCopyModal, setShowCopyModal] = useState(false)
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [pdfProgress, setPdfProgress] = useState(0)
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 100) {
      setBackTop(true)
    } else {
      setBackTop(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const copyLinkUrl = () => {
    setLinkCopied(true);
    copy(shareUrl);
    setIsPopupOpen(false)
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  const getToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const scrollThumb = document.getElementsByClassName('scrollThumb')[0]
  if(scrollThumb)scrollThumb.style.backgroundImage = 'url(https://assets.graphia.in/site_media/wedgraphia/slider_scroll.png)';

  const mobScrollThumb = document.getElementsByClassName('mCSB_dragger_bar')[0]
  if(mobScrollThumb)mobScrollThumb.style.backgroundImage = 'url(https://assets.graphia.in/site_media/wedgraphia/slider_scroll.png)';

  const queryParams = new URLSearchParams(window.location.search);
  const portCondition =
    dimensions.height < dimensions.width ? "WGWhyDiv" : "WGPortImg";
  const portDurDiv =
    dimensions.height < dimensions.width ? "WGDur-div" : "WGPortDur-div";
  const WGPortQuotTeamDiv =
    dimensions.height < dimensions.width ? "WG_Quot_TeamDiv" : "WGPortQuotTeam";
  const id = queryParams.get("id");

  const showCopyModalFlag = queryParams.get("showCopyModal");

  useEffect(() => {
    if(isAdmin() && showCopyModalFlag === 'true'){
      setShowCopyModal(true);
    }else{
      setShowCopyModal(false);
    }
  }, []);

  useEffect(() => {
    if (dimensions.width > 700) {
      setShowMore(true);
    }
  }, []);


  const downloadPDF = async () => {
    document.body.style.overflowY = 'hidden';
    setLoading(true)
    const input = document.getElementById('pdf-content');
    const pages = input.querySelectorAll('.pdf-page');
    const colorless = document.getElementsByClassName('pdf-page')
    const backgroundless = document.getElementsByClassName('WG_Quot_TeamDiv')
    const durDivStyle = document.getElementsByClassName('WGDur-div')
    const detailDisplay = document.getElementsByClassName('detailClass')
    for (let i = 1; i < colorless.length; i++) {
      colorless[i].style.background = 'white'
    }
    if (detailDisplay[0]) detailDisplay[0].style.display = 'none'
    if (backgroundless[0]) {
      backgroundless[0].style.background = 'none'
      backgroundless[0].style.color = 'rgb(84, 77, 68)'
    }
    if (durDivStyle[0]) {
      durDivStyle[0].style.background = 'none'
      durDivStyle[0].style.color = 'rgb(84, 77, 68)'
    }

    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [16, 6.5],
      compress: true,
    });

    const addPage = (index) => {
      if (index > 0) {
        pdf.addPage();
      }
      index === 0 && pdf.setFillColor(212, 191, 172)
      index === 0 && pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
      return pdf;
    };

    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];
      try {
        const canvas = await html2canvas(page);
        const imgData = canvas.toDataURL('image/png', 0.2);
        const pdfInstance = addPage(index);

        const scaleFactorX = pdfInstance.internal.pageSize.getWidth() / canvas.width;
        const scaleFactorY = pdfInstance.internal.pageSize.getHeight() / canvas.height;
        const scaleFactor = Math.min(scaleFactorX, scaleFactorY);

        const imgWidth = index === 0 && index === 1 ? pdfInstance.internal.pageSize.getWidth() : canvas.width * scaleFactor;
        const imgHeight = index === 0 && index === 1 ? pdfInstance.internal.pageSize.getHeight() : canvas.height * scaleFactor;

        const offsetX = (pdfInstance.internal.pageSize.getWidth() - imgWidth) / 2;
        const offsetY = (pdfInstance.internal.pageSize.getHeight() - imgHeight) / 2;
        pdfInstance.addImage(imgData, 'PNG', offsetX, offsetY, imgWidth, imgHeight);

        if (index === pages.length - 1) {
          pdfInstance.save(`${data.estimation_details.quot_title}.pdf`);
          const backDetails = document.getElementsByClassName('indi-details')
          for (let i = 1; i < colorless.length; i++) {
            colorless[i].style.backgroundColor = '#FBF7F4'
          }
          for (let i = 0; i < backDetails.length; i++) {
            backDetails[i].style.background = 'rgb(212, 191, 172)'
          }
          colorless[0].style.background = 'rgb(212, 191, 172)'
          if (durDivStyle[0]) {
            durDivStyle[0].style.background = `linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(https://assets.graphia.in/site_media/wedgraphia/react/backdrops/Landscape/2.jpg)`
            durDivStyle[0].style.color = 'white'
          }
          if (backgroundless[0]) {
            backgroundless[0].style.background = `linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(https://assets.graphia.in/site_media/wedgraphia/react/backdrops/Landscape/1.jpg)`
            backgroundless[0].style.color = 'white'
          }
          if (detailDisplay[0]) detailDisplay[0].style.display = ''
          setLoading(false)
          document.body.style.overflowY = 'scroll';
        }
        setPdfProgress(`${parseInt((index + 1) / pages.length * 100, 10)}`)
      } catch (error) {
        console.error('Error occurred while processing page:', error);
      }
    }
  };


  useEffect(() => {
    if (addPopUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [addPopUp]);

  if (id === null) {
    navigate("/");
  }
  const { storeToLocal } = StoreToken();
  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const element = document.getElementById("aaaa-6");
  useEffect(() => {
    $("#aaaa-6").mCustomScrollbar({
      axis: "x",
      theme: "dark",
      scrollButtons: {
        enable: true,
      },
      scrollbarPosition: "outside",
      advanced: {
        autoExpandHorizontalScroll: true,
      },
      autoDraggerLength: false,
      alwaysShowScrollbar: 1,
      mouseWheel: {
        scrollAmount: 40,
      },
      scrollInertia: 1750,
      callbacks: {},
    });

    return () => {
      $("#aaaa-6").mCustomScrollbar("destroy");
    };
  }, [element]);
  useEffect(() => {
    if (isAdmin() && !preview) {
      return;
    }
    if (!isAdmin() && preview) {
      return;
    }
    var api_url = `${process.env.REACT_APP_STAG_URL}/api/estimation/estimation/${id}/`
    if (preview) {
      api_url = `${process.env.REACT_APP_STAG_URL}/api/estimation/estimation/${id}/preview/`
    }
    axios({
      url: api_url,
      method: "get",
      headers: {
        Authorization: "Token " + localStorage.getItem("eventGraphiaToken"),
      },
    })
      .then((response) => {
        setData(response.data);
        let prevent_downloadable = ((dimensions.height > dimensions.width) || !(preview || response.data.estimation_details.is_downloadable))
        setPreventDownloadable(prevent_downloadable)
      })
      .catch((err) => {
        if (err.response.status == 401) {
          getLoginPage();
        }
      });
  }, [element]);

  useEffect(()=>{
    if(preview){
      const scheduleElement = document.getElementById('scheduleDiv')
      if (scheduleElement) {
        scheduleElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },[data])

  const shareFunc = () => {
    setIsPopupOpen(true)
    for (let i = 0; i < data.estimation_urls.length; i++) {
      if (data.estimation_urls[i].is_checked === true) {
        setShareUrl(data.estimation_urls[i].url);
      }
    }
  }

  return !data ? (
    <div
      className='overlayLoader'
    >
      {globalLoader()}
    </div>
  ) : (
    <>
      {data.estimation_details.quot_title && <Helmet>
        <title>{data.estimation_details.quot_title} | WedGraphia</title>
      </Helmet>}
      <div>
        {backTop && <div style={{ bottom: (preventDownloadable) ? '19vh' : '26vh' }} className="backToTop" onClick={getToTop}>
          <div><FontAwesomeIcon icon={faArrowUp} style={{ color: "#000000", }} /></div>
        </div>}
        <div style={{ bottom: (preventDownloadable) ? '11vh' : '19vh' }} className="shareButton">
          <div onClick={shareFunc}>
            <FontAwesomeIcon
              icon={faShareNodes}
              style={{ color: "#000000" }}
            />
          </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
        >
          <div style={{ textAlign: "center", padding: "0 30px" }}>
            <div style={{ fontSize: "large", paddingTop: "0.2rem" }}>
              Share this quotation
            </div>
            <div onClick={copyLinkUrl} className="cpyAlbum">
              <FontAwesomeIcon
                icon={faCopy}
                style={{ color: "#ffffff" }}
              />
              Copy Link
            </div>
            <Link to={`${getWhatsappShareUrl(shareUrl)}`}>
              <button className="shareWp">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  style={{ color: "#ffffff" }}
                />
                Share
              </button>
            </Link>
          </div>
        </Popup>
        {showCopyModal && 
          (<div className="copyModal">
          <div className="copyModalText">
          <div>URL has been copied to the clipboard! <br />
          <small>This is a one time link, do not open before sending to the customer.</small></div>
            <button className="copyModalBtn" onClick={()=> setShowCopyModal(false)}>
              Okay
            </button>
          </div>
        </div>)
        }
        <div style={{ display: (preventDownloadable) && "none" }} className='downFloat' onClick={downloadPDF}>
          <FontAwesomeIcon icon={faDownload} style={{ color: "#000000" }} />
        </div>
        {loading &&
          <div className='overlayLoader'>
            {globalLoader()}
            <div style={{ color: "white", marginTop: "10px" }}>Generating PDF ({pdfProgress}%)</div>
          </div>}
        {linkCopied && (
          <div
            style={{
              position: "fixed",
              bottom: "11vh",
              borderRadius: "0.5rem",
              right: "1rem",
              padding: "0.3rem 1.5rem",
              zIndex: "1000",
              backgroundColor: "rgba(0,0,0,0.7)",
            }}
          >
            <div
              id="liveToast"
              className="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-body" style={{ color: "white" }}>
                Link Copied
              </div>
            </div>
          </div>
        )}
        <div className="name_title">{data.estimation_details.quot_title}</div>
        <div id="pdf-content">
          <div
            ref={divRef}
            style={{
              display: data.estimation_urls.length <= 1 ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <div className="quote_navDiv">
              {dimensions.width ? (
                dimensions.width > 600 ? (
                  data.estimation_urls.map((e, index) => {
                    return (
                      <a key={index} href={e.url}>
                        <button
                          disabled={e.is_checked && true}
                          style={{
                            margin: "0 40px",
                            padding: "10px 30px",
                            border: "1px solid black",
                            borderRadius: "1rem",
                            backgroundColor: e.is_checked && "rgba(0,0,0,0.7)",
                            color: e.is_checked && "white",
                          }}
                        >
                          {quotation_string(e.version)}{" "}<div className='finalTag' style={{ display: e.is_final ? "inline-block" : "none" }}>final</div>
                        </button>
                      </a>
                    );
                  })
                ) : (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                      style={{ backgroundColor: "#FBF7F4", border: "1px solid black" }}
                    >
                      {data.estimation_urls.map((e) => {
                        if (e.is_checked)
                          return <>{quotation_string(e.version)}<div className='finalTag' style={{ display: e.is_final ? "inline-block" : "none" }}>final</div></>;
                      })}
                    </MenuButton>
                    <MenuList style={{ width: "fit-content", backgroundColor: "#FBF7F4" }}>
                      {data.estimation_urls.map((e) => {
                        return (
                          <MenuItem
                            isDisabled={e.is_checked ? true : false}
                            as="a"
                            href={`${e.url}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "#FBF7F4",
                            }}
                          >
                            {quotation_string(e.version)}<div className='finalTag' style={{ display: e.is_final ? "inline-block" : "none" }}>final</div>
                            {e.is_checked && (
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{ color: "#69b190", marginRight: "10px" }}
                              />
                            )}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                )
              ) : (
                globalLoader('lg')
              )}
            </div>
          </div>
          <div
            className="pdf-page"
            style={{
              height: "65vh",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(212,191,172)",
            }}
          >
            <img
              className="egLogo"
              src={wg_logo}
              alt="titleImage"
              style={{
                filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4))'
              }}
            />
          </div>
          <div className="WG_Quot_Sched pdf-page" id="scheduleDiv">
            <h1 className="WG_Quot_Title">SCHEDULE</h1>
            <div className="WG_allDates_Sched">
              {data.estimation_details.estimation_data.map((e, index) => {
                return (
                  <>
                    <div key={index} className="wholeDateEvent">
                      <div className="WG_Date_Event">{e.date_data.date_str}</div>
                      <ul
                        style={{
                          paddingLeft: "0.8rem",
                          color: "rgb(84, 77, 68)",
                        }}
                      >
                        {e.events_name.map((event, index) => {
                          return (
                            <>
                              <li key={index}>{event}</li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="wg-eventDate-Details">
            {data.estimation_details.estimation_data.map((e, index) => {
              return (
                <>
                  <div key={index} className='indi-details pdf-page'>
                    <div
                      id="currentDate"
                      style={{
                        textAlign: dimensions.width < 600 ? "center" : "left",
                      }}
                      key={index}
                      className="WG_Quot_Title"
                    >
                      {e.day_info}
                    </div>
                    {e.events_count === 1 && e.event_data[0] && <div className="wg_location_Title">({e.event_data[0].venue_str})</div>}
                    {/* {e.events_count === 1 && e.event_data[0] && <div className="gathering_Title">{e.event_data[0].gathering_str ? e.event_data[0].gathering_str : ""}</div>} */}
                    <div className="part-Details">
                      {e.event_data.map((event, index) => {
                        if (index < 2) {
                          return (
                            <>
                              <div key={index} className="indi-part">
                                <div style={{ display: e.events_count === 1 && "none" }} className="wg-event-detail">{event.event_name}</div>
                                <div style={{display: (e.events_count === 1 || !event.venue_str) && "none"}} className="wg-event-venue">{event.venue_str}</div>
                                {/* <div style={{display: (e.events_count === 1 || !event.gathering_str) && "none"}} className="wg-event-gathering">{event.gathering_str}</div> */}
                                <ul style={{ color: "rgb(84, 77, 68)", maxWidth: dimensions.width > 700 ? "70%" : "100%" }}>
                                  {event.event_products_cnt.map((x, index) => {
                                    return (
                                      <>
                                        <li key={index}>{x}</li>
                                      </>
                                    );
                                  })}
                                </ul>
                                <div style={{ display: !event.display_coverage && "none" }} className="wg_coverage_till">*{event.display_coverage}</div>
                                {event.notes.map((e, index) => {
                                  return (
                                    <>
                                      <div key={index} style={{ display: !e && 'none' }} className="wg_coverage_till">*{e}</div>
                                    </>
                                  )
                                })}
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                  </div>
                  {e.event_data.length > 2 && e.event_data.map((ev, index) => {
                    if (index > 1 && (index % 2 === 0)) {
                      return (
                        <>
                          <div className='indi-details pdf-page'>
                            <div className='part-Details'>
                              <div className='indi-part'>
                                <div style={{ display: ev.events_count === 1 && "none" }} className="wg-event-detail">{ev.event_name}</div>
                                <div style={{display: (ev.events_count === 1 || !ev.venue_str) && "none"}} className="wg-event-venue">{ev.venue_str}</div>
                                <ul style={{ color: "rgb(84, 77, 68)", maxWidth: dimensions.width > 700 ? "70%" : "100%" }}>
                                  {ev.event_products_cnt.map((x, index) => {
                                    return (
                                      <>
                                        <li key={index}>{x}</li>
                                      </>
                                    );
                                  })}
                                </ul>
                                <div style={{ display: !ev.display_coverage && "none" }} className="wg_coverage_till">*{ev.display_coverage}</div>
                                {ev.notes.map((y, index) => {
                                  return (
                                    <>
                                      <div key={index} style={{ display: !y && 'none' }} className="wg_coverage_till">*{y}</div>
                                    </>
                                  )
                                })}
                              </div>
                              {e.event_data[index + 1] && <div className='indi-part'>
                                <div style={{ display: e.event_data[index + 1].events_count === 1 && "none" }} className="wg-event-detail">{e.event_data[index + 1].event_name}</div>
                                <div style={{display: (e.event_data[index+1].events_count === 1 || !e.event_data[index+1].venue_str) && "none"}} className="wg-event-venue">{e.event_data[index+1].venue_str}</div>
                                <ul style={{ color: "rgb(84, 77, 68)", maxWidth: dimensions.width > 700 ? "60%" : "100%" }}>
                                  {e.event_data[index + 1].event_products_cnt.map((x, index) => {
                                    return (
                                      <>
                                        <li key={index}>{x}</li>
                                      </>
                                    );
                                  })}
                                </ul>
                                <div style={{ display: !e.event_data[index + 1].display_coverage && "none" }} className="wg_coverage_till">*{e.event_data[index + 1].display_coverage}</div>
                                {e.event_data[index + 1].notes.map((y, index) => {
                                  return (
                                    <>
                                      <div key={index} style={{ display: !y && 'none' }} className="wg_coverage_till">*{y}</div>
                                    </>
                                  )
                                })}
                              </div>}
                            </div>
                          </div>
                        </>
                      )
                    }
                  })}
                  {index + 1 !==
                    data.estimation_details.estimation_data.length && (
                      <hr className="rounded breakLine"></hr>
                    )}
                </>
              );
            })}
          </div>
          {data.estimation_details.production_team.length > 0 && (
            <div className={`${WGPortQuotTeamDiv} pdf-page`}>
              <div>
                <div className="WG_Quot_Title_Light">PRE & POST PRODUCTION TEAM</div>
                <ul
                  style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                >
                  {data.estimation_details.production_team.map((e, index) => {
                    return (
                      <>
                        <li key={index}>
                          {e.count} {e.name}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
          <div className="wg_del-div pdf-page">
            <div>
              <div className="WG_Quot_Title">DELIVERABLES</div>
              <ul style={{ color: "rgb(84, 77, 68)" }}>
                {data.estimation_details.booking_products.map((e, index) => {
                  return (
                    <>
                      <li key={index}>
                        <span style={{ fontWeight: e.display_data.display_name.bold ? "bold" : "" }}>
                          {e.display_data.display_name.lname}{e.display_data.display_name.rname ? ":" : ""}
                        </span> {e.display_data.display_name.rname}
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={`${portDurDiv} pdf-page`}>
            <div>
              <div className="WG_Quot_Title_Light">
                DELIVERY DURATION
              </div>
              <ul style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}>
                {data.estimation_details.delivery_duration.delivery_data.map((e, index) => {
                  return (<>
                    <li key={index}>
                      <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <span style={{ width: "60%" }}>{e.prod_name}</span>
                          <span>{e.duration}</span>
                        </div>
                        <div style={{ display: e.help_text === null && "none", fontSize: "0.9rem", textAlign: "left" }}>({e.help_text})</div>
                      </div>
                    </li>
                  </>)
                })}
              </ul>
              {data.estimation_details.delivery_duration.duration_strings.map((e, index) => {
                return (<>
                  <div
                    style={{ display: !e && "none", textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                    className="delivery_detail"
                  >
                    * {e}
                  </div>
                </>)
              })}
              <div
                style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                className="delivery_detail"
              >
                * The delivery duration starts upon receipt of full payment for the amount due post-shoot.
              </div>
              <div
                style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                className="delivery_detail"
              >
                * We do 1 Iteration for all the videos and the changes need to be
                told within 30 days of video delivery.
              </div>
              <div
                style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                className="delivery_detail"
              >
                * Delivery timelines may vary a bit in peak wedding season.
              </div>
            </div>
          </div>
          <div className="wg-ex-div">
            <div className="wg-availablity-ex">
              Exclusively Available at WedGraphia
            </div>
            <div className="wg-available-dev">
              The deliverables would be made available in WedGraphia Website
              with Smart Sharing Feature.
            </div>
            <div className="wg-available-mem">
              You can share your sweet memories with relatives and family via our
              website anytime, anywhere.
            </div>
          </div>
          <div className="quote-div pdf-page">
            <div className="WG_Quot_Title">QUOTATION</div>
            <div className="pricing-detail">
              {data.estimation_details.preview_list.map((e, index) => {
                return (
                  <>
                    <div
                      style={{
                        color: e.display_name.web_color_code
                          ? e.display_name.web_color_code
                          : "rgb(84, 77, 68)",
                        fontWeight: e.display_name.bold ? "bold" : "",
                        display: "flex",
                        justifyContent:
                          data.estimation_details.preview_list.length > 1
                            ? "space-between"
                            : "center",
                        width: "100%",
                        lineHeight: "normal",
                        paddingBottom: "1.5rem",
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          maxWidth: "60%",
                          textAlign: "left"
                        }}
                      >
                        {e.display_name.lname} : {" "}
                        {e.details && (
                          <span
                            style={{
                              fontSize: "55%",
                              color: "rgb(84, 77, 68)",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: "bolder"
                            }}
                            className='detailClass'
                            onClick={() => {
                              setAddPopUp(true);
                            }}
                          >
                            See details
                          </span>
                        )}
                        {e.display_substr && (
                          <span
                            style={{
                              display: e.display_substr ? "block" : "none",
                              fontSize: "55%",
                            }}
                          >
                            {" "}
                            ({e.display_substr.name})
                          </span>
                        )}
                      </span>
                      <span>  &nbsp;{e.display_name.rname}</span>
                    </div>
                  </>
                );
              })}
            </div>
            {data.estimation_details.inclusions_data.map((e, index) => {
              return (
                <>
                  <div key={index} className="WG_Quot_point_first">
                    *{e.desc}
                  </div>
                </>
              )
            })}
            {data.estimation_details.discount_validity && (
              <div className="WG_Quot_point">
                * Note: Due date for this price is (
                {data.estimation_details.discount_validity}), for any confirmation
                post due date WedGraphia reserves the rights to revise the rates
                over an updated proposal.
              </div>
            )}
            <div style={{ display: data.estimation_details.is_final && 'none' }} className="WG_Quot_point">
              * This proposal does not guarantee that teams and dates are blocked,
              availability would be subjected to the date of confirmation.
            </div>
          </div>
          {isMobile() ? <div style={{ paddingBottom: "4rem" }} className="wedding__gallery">
            <div
              id="aaaa-6"
              className="aaaa customScroller"
            >
              <ul>
                {data.wedding_slider_urls.map((e, index) => {
                  return (
                    <>
                      <li key={index}>
                        <img loading="lazy" src={e} />
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div> :
            <Slider data={data} />}
          {data.estimation_details.additional_products.length > 0 && (
            <div className="wg-addOn-div">
              <div className="WG_Quot_Title">Add-ons</div>
              <ul className="wg-additional-quot">
                {data.estimation_details.additional_products.map((e, index) => {
                  return (
                    <>
                      <li>
                        {e.description ? (
                          <>
                            <div style={{ maxWidth: "60%" }}>{e.display_name}</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <span style={{ maxWidth: "60%" }}>{e.description}</span>
                              <span style={{ display: "inline-block" }}>
                                {e.display_cost}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ maxWidth: "60%" }}>{e.display_name}</span>
                            <span style={{ display: "inline_block" }}>
                              {e.display_cost}
                            </span>
                          </div>
                        )}
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="payPolicy-div pdf-page">
            <div className="WG_Quot_Title">PAYMENT POLICY</div>
            <ul className="wg-additional-quot">
              {data.estimation_details.installment_data.map((e, index) => {
                return (
                  <>
                    <li key={index}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                      <span style={{width:"60%"}}>{e.display_name.lname} {e.display_name.percent} {e.display_name.desc}:</span>
                        <span>{e.display_name.rname}</span>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="wg-cancPolicy-div pdf-page">
            <div className="WG_Quot_Title">Cancellation Policy</div>
            <div className="wg-cp-subheader">
              In case of any cancellation by the client, the following applies:
            </div>
            <div className="table-container">
              <table className="wg-custom-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Cancellation Time</th>
                    <th>Cancellation Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {data.estimation_details.cancellation_policy.map((e, index) => {
                    return (
                      <tr>
                        <td>{e.time_str}</td>
                        <td>{e.display_fee}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="termsCondition pdf-page">
            <div className="WG_Quot_Title">Terms & Conditions</div>
            {showMore ? (
              <ul style={{ color: "rgb(84, 77, 68)" }}>
                <li>
                  <span className="terms-point">Final Product : </span>It is
                  understood that the Client is hiring the WedGraphia for its
                  technical and artistic expertise and, therefore, the
                  WedGraphia reserves the right to edit the Images as it sees
                  fit. The WedGraphia does not guarantee that everyone (each and
                  every guest) involved in the event will be photographed or will
                  be included in the final Images.
                </li>
                <li>
                  <span className="terms-point">Copyright : </span>WedGraphia
                  retains the right to use the pictures & videos that the
                  organisation produces for the purpose of publicity on the
                  company website or social media platforms.
                </li>
                <li>
                  <span className="terms-point">Point of Contact : </span>It is
                  required to appoint one point of contact from the host’s side
                  guiding our team to make sure that the optimum coverage is
                  achieved, leaving none of the close friends or family members
                  unphotographed.
                </li>
                <li>
                  <span className="terms-point">
                    Electricity outlet and place for equipment :{" "}
                  </span>
                  5amps/220-230 volts has to be provided at the ceremony location
                  along with a 4’x2’-table to set up our audio recording devices
                  and laptops
                </li>
                <li>
                  <span className="terms-point">Drone (if applicable): </span>
                  Permission to use Drones at the specified location needs to be
                  procured by the client from the local police administration. In
                  case of any dispute at the venue, WedGraphia will not be held
                  responsible or liable for any refunds.
                </li>
                <li>
                  <span className="terms-point">F&B </span>for the entire crew has
                  to be organized in the guest areas.{" "}
                </li>
              </ul>
            ) : (
              <ul style={{ color: "rgb(84, 77, 68)" }}>
                <li className="collapsed">
                  <span className="terms-point">Final Product : </span>
                  It is understood that the Client is hiring the WedGraphia for
                  its technical and artistic expertise and, therefore, the
                  WGraphia reserves the right to edit the Images as it sees
                </li>
                <span
                  style={{
                    textAlign: "center",
                    display: showMore ? "none" : "block",
                    width: "100%",
                    backgroundColor: "rgba(0,0,0,0)",
                    borderRadius: "10px",
                  }}
                  className="colButton"
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ color: "black" }}
                    size="lg"
                  />
                </span>
              </ul>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div className={`${portCondition}`}>
              <div>
                <h1 className="WG_Quot_Title_Light">WHY WEDGRAPHIA?</h1>
                <div
                  style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                  className="whyText"
                >
                  WedGraphia is a Premium Event Photography Service space. We
                  provide seamless end to end solutions right from booking inquiry
                  to the delivery of photos, videos and albums, all digitally
                  using our android/ios/web app.
                </div>
                <ul
                  style={{ textShadow: "2px 2px 4px rgba(250, 250, 250, 0.7)" }}
                >
                  <li>India’s Largest Event Photography Company</li>
                  <li>Serving 175+ Cities</li>
                  <li>210+ Professionals</li>
                  <li>Covered 500+ Weddings</li>
                  <li>State of the art Equipments</li>
                  <li>
                    Customers - BMW, TAJ, Uber, Dominos, Harley Davidson, Hello
                    English, Oyo Rooms and many more.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="Quot_Footer">
            <div className="footerLink">
              <a
                target="_blank"
                style={{ display: "flex" }}
                href="https://www.instagram.com/wedgraphia/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="white"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  />
                </svg>
                <p style={{ marginLeft: "10px" }}>Instagram</p>
              </a>
              <p style={{ padding: "0 20px" }}>|</p>
              <a
                target="_blank"
                style={{ display: "flex" }}
                href={`${data.estimation_details.portfolio_url}`}
              >
                <FontAwesomeIcon icon={faLink} size="lg" />
                <p style={{ marginLeft: "10px", fontWeight: "bolder" }}>
                  Portfolio Link
                </p>
              </a>
            </div>
          </div>
          {addPopUp &&
            data.estimation_details.preview_list.map((e, index) => {
              return (
                <>
                  {e.details && (
                    <div
                      style={{
                        display: "flex",
                        position: "fixed",
                        top: "0",
                        backgroundColor: "rgba(0,0,0,0.7)",
                        width: "100%",
                        height: "100%",
                        left: "0",
                        zIndex: "100",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="popupOverlay">
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            setAddPopUp(false);
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                          >
                            Additional Charges
                          </span>
                          <FontAwesomeIcon
                            icon={faXmark}
                            style={{ color: "#000000" }}
                          />
                        </div>
                        <hr style={{ margin: "15px 0" }} />
                        <div
                          style={{
                            color: "black",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {e.details.map((e, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingBottom: "10px",
                                }}
                              >
                                <span>{e.display_name.lname}</span>
                                <span>{e.display_name.rname}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default WGQuotation;
