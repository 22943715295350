import React from 'react'
import { GetProfile, getLoginPage, getQueryVariable } from '../script/util';
import { useStateContext } from '../context/StateContext'

const StoreToken = () => {
    const { isLoggedin, setisLoggedin } = useStateContext();
    const storeToLocal = () => {
        if (getQueryVariable("token")) {
          // localStorage.clear();
          localStorage.setItem("eventGraphiaToken", getQueryVariable("token"));
        }
    
        if (localStorage.eventGraphiaToken !== undefined) {
          setisLoggedin(true);
          GetProfile();
        } else {
          setisLoggedin(false);
          getLoginPage();
        }
      };
  return {
    storeToLocal
  }
}

export default StoreToken