import * as React from 'react'
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { StateContext } from './context/StateContext';
import { extendTheme } from "@chakra-ui/react"
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "#fbf7f4",
      },
      colors:{
        white:"#fbf7f4"
      }
    }),
  },
});
root.render(
  <StateContext>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  </StateContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
