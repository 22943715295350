import React from 'react'
import './Popup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Popup = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="popup-close-button" onClick={onClose}>
                    <FontAwesomeIcon size='xl' icon={faXmark} />
                </button>
                {children}
            </div>
        </div>
    )
}

export default Popup