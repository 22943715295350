import React, { useEffect, useRef, useState } from "react";
import './Slider.css'

const Slider = ({ data }) => {
    const manualScroll = useRef(false)
    const scrollableRef = useRef(null)
    const mobileScroll = useRef(false)
    const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    });
    const [startX, setStartX] = useState(0);
    const [dragging, setDragging] = useState(false);
    const el = document.getElementsByClassName('unordered')
    const sliderDiv = document.getElementsByClassName('mySlider')
    const barDivWidth = document.getElementsByClassName('mainBar')
    const imgBarDiv = document.getElementsByClassName('scrollThumb')
    const imgWidth = imgBarDiv[0] && imgBarDiv[0].getBoundingClientRect().width
    const scroll_width = el[0] && el[0].scrollWidth;
    const width = barDivWidth[0] && barDivWidth[0].getBoundingClientRect().width;
    const initialX = width*0.05;
    const scroll_percent = (startX-initialX)/((width*0.90)-imgWidth)
    const [touchSupported, setTouchSupported] = useState(false);

    const seekSlider = (event) =>{

      if(startX===0){
        setStartX(initialX+10)
      }else if(startX<event.clientX){
        slideRight()
      }else{
        slideLeft()
      }
    }

    useEffect(() => {
      const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
      setTouchSupported(isTouchDevice);
    }, []);
    
    const onMouseLeave = () =>{
      if(!dragging)manualScroll.current = false
    }
        
    const onMouseEnter = () =>{
        if(!dragging)manualScroll.current = true    
    }
        
    const handleMouseDown = (event) => {
      mobileScroll.current = true
      setDragging(true);
    };
        
    const handleMouseUp = (e) => {
      setTimeout(()=>{
        mobileScroll.current = false
      },750)
      setDragging(false);
    };
        
    function handleMouseMovement(event) {
        if(!dragging) return;
        var x = event.clientX;
        if(event.type === 'touchmove'){
        x = event.touches[0].clientX
        }
        if(x < (width*0.95)-imgWidth && x > width*0.05){
            setStartX(x)
        }
    }

    const handleMouseMove = (event) => {
      if (!dragging) return;
    };
    const bar = document.getElementsByClassName('mainSlider')[0]
    useEffect(() => {
    bar && bar.addEventListener('mousemove', handleMouseMovement);
    bar && bar.addEventListener('touchmove', handleMouseMovement);
    bar && bar.addEventListener('mouseup', handleMouseUp);
    bar && bar.addEventListener('touchup', handleMouseUp);

    // Cleanup event listeners when component unmounts
    return () => {
        bar && bar.removeEventListener('mousemove', handleMouseMovement);
        bar && bar.removeEventListener('touchmove', handleMouseMovement);
        bar && bar.removeEventListener('mouseup', handleMouseUp);
        bar && bar.removeEventListener('touchup', handleMouseUp);
    };
    }, [dragging, scroll_percent]);
    
    useEffect(()=>{
        if(!manualScroll.current && !(!mobileScroll.current && touchSupported)){
          const tobe_scrolled = scroll_percent*(scroll_width*0.95);
          sliderDiv[0] && sliderDiv[0].scroll({
          left: tobe_scrolled,
          behavior: 'smooth'
          })
        }
        if(touchSupported){
          setTimeout(()=>{
            mobileScroll.current = false
          },750)
        }
    },[startX])
    
    const scrollableElement = scrollableRef.current;
    const handleScrollSlider = (event) => {
      var autoScroll = scrollableRef.current.scrollLeft;
      var scrollAutoPercent = dimensions.width > 1200 ? autoScroll/(scroll_width*0.95) : autoScroll/(scroll_width);
      var scrolling = (scrollAutoPercent*(width*0.90))+initialX
      if(scrolling < width*0.95-imgWidth && scrolling > width*0.05){
        manualScroll.current && setStartX(scrolling)
        !mobileScroll.current && touchSupported && setStartX(scrolling)
      }
    };
    
    useEffect(()=>{
    if (scrollableElement) {
        scrollableElement.addEventListener('scroll', handleScrollSlider);
    }

    return () => {
        if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScrollSlider);
        }
    };
    },[scrollableElement])
    
    const slideRight = () => {
      mobileScroll.current = true
      if(((startX+imgWidth)+10) < width*0.95){
        if(startX===0){
          setStartX(initialX+10)
        }else {
          setStartX((prevPosition) => prevPosition + 10); 
        }
      } 
    };

    const slideLeft = () => {
      mobileScroll.current = true
      if((startX-10) > width*0.05)setStartX((prevPosition) => prevPosition - 10); 
    };

    return (
    <div className='mainSlider' onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp} style={{userSelect: "none"}}>
        <div ref={scrollableRef} className='mySlider' 
        onMouseEnter={onMouseEnter} 
        onMouseLeave={onMouseLeave}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseDown}>
            <ul className="unordered" style={{ listStyleType: 'none', padding: '10px' }}>
              {data.wedding_slider_urls.map((e,index)=>{
                return (
                  <>
                    <li key={index} style={{ display: 'inline-block', paddingRight: "10px" }}>
                      <img style={{height: dimensions.width>600 ? "370px" : '210px', width: "auto"}} src={e}/>
                    </li>
                  </>
                )
              })}
            </ul>
          </div>
          <div className="mainBar">
            <div id='bar_scroll' className='navRight' onClick={slideLeft}>&lt;</div>
            <div className='seek_slider' onClick={seekSlider}>
              <div style={{left: startX ===0 ? initialX : startX}} className='scrollThumb' 
              onMouseDown={handleMouseDown} 
              onMouseUp={handleMouseUp} 
              onMouseMove={handleMouseMove}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              onTouchMove={handleMouseMove}></div>
            </div>
            <div className='navLeft' onClick={slideRight}>&gt;</div>
          </div>
    </div>
    )
}

export default Slider
