import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./AlbumView.css";
import {
  GetProfile,
  getAPIUrl,
  getAuthToken,
  getLoginPage,
  getQueryVariable,
  isAdmin,
  get_logo
} from "../../script/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faShareNodes,
  faCopy,
  faPause,
  faArrowLeft,
  faArrowRight,
  faAngleRight,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { useStateContext } from "../../context/StateContext";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import Pageflip from "../Pageflip";
import Popup from "../Popup/Popup";
import { Link, useNavigate } from "react-router-dom";
import copy from "clipboard-copy";
import StoreToken from "../StoreToken";
import { getWhatsappShareUrl, globalLoader } from '../../script/util.js'

const AlbumView = () => {
  const { isLoggedin, setisLoggedin } = useStateContext();
  const [albumData, setAlbumData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [shareUrl, setShareUrl] = useState();
  const [linkCopied, setLinkCopied] = useState(false);
  const rect = useRef();
  const [bookingPK, setBookingPK] = useState()
  const goFull = useRef(false);
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState();
  const [rotate, setRotate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const id = useRef();
  const playRef = useRef();
  const navigate = useNavigate();
  const { storeToLocal } = StoreToken();
  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    id.current = queryParams.get("id");
    if (id.current === null) {
      navigate("/");
    }
    id &&
      axios({
        method: "get",
        url: getAPIUrl(`/api/work/${id.current}/`),
        headers: {
          Authorization: getAuthToken(),
        },
        dataType: "json",
        cache: false,
      })
        .then(function (res) {
          const tp = res.data;
          if (tp.success) {
            setAlbumData(tp);
            setBookingPK(tp.booking_pk)
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            getLoginPage();
          }
        });
  }, []);
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsPlaying(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);
  const updateDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    setDivHeight(height);
    if (width + 100 < height) {
      setRotate(true);
    } else {
      setRotate(false);
    }
  };
  useEffect(() => {
    if (!document.webkitIsFullScreen) {
      setIsPlaying(false);
    }
  }, [document.webkitIsFullScreen]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const headers = { Authorization: getAuthToken() };
  const inputData = { data_id_list: [`${id.current}`] };
  const ShareCall = () => {
    setIsOpen(false);
    setIsPopupOpen(true);
    axios
      .post(getAPIUrl(`/api/share/url/${bookingPK}/`), inputData, { headers })
      .then(function (res) {
        console.log(res);
        setShareUrl(res.data.share_url);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          getLoginPage();
        }
      });
  };

  const copyLinkUrl = () => {
    setLinkCopied(true);
    copy(shareUrl);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };
  if (isPlaying) {
    if(!(document.documentElement.mozRequestFullScreen)){
      document.documentElement.webkitRequestFullscreen();
    }else{
      document.documentElement.mozRequestFullScreen()
    }
    goFull.current = true;
  } else {
    if (goFull.current) {
      document.exitFullscreen();
      goFull.current = false;
    }
  }

  return (
    <>
      {albumData ? (
        <>
          <div
            ref={divRef}
            className="album_mainDiv"
            onClick={() => {
              isOpen && setIsOpen(false);
            }}
          >
            {!rotate ? (
              <>
                <div className="navDiv">
                  <div>
                    <img
                      className="logoWidth"
                      src={get_logo()}
                    />
                  </div>
                  {divHeight > 600 && (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ color: "white", fontWeight: "900" }}>
                        {albumData.title}
                      </div>
                      <div style={{ color: "white", fontSize: "0.8rem" }}>
                        {albumData.subtitle}
                      </div>
                    </div>
                  )}
                  <div style={{ display: "flex" }}>
                    <div
                      className="playButtonClass"
                      style={{ margin: "0 0.8rem" }}
                      ref={playRef}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          setIsPlaying((prev) => !prev);
                          goFull.current = `${isPlaying}`;
                        }}
                        icon={isPlaying ? faPause : faPlay}
                        size="xl"
                        style={{ color: "#ffffff" }}
                        ref={playRef}
                      />
                    </div>
                    <div style={{ margin: "0 0.8rem", cursor: "pointer", display: isAdmin() && 'none' }}>
                      <FontAwesomeIcon
                        onClick={ShareCall}
                        icon={faShareNodes}
                        size="xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                </div>
                <Popup
                  isOpen={isPopupOpen}
                  onClose={() => {
                    setIsPopupOpen(false);
                  }}
                >
                  <div style={{ textAlign: "center", padding: "0 30px" }}>
                    <div style={{ fontSize: "large", paddingTop: "0.2rem" }}>
                      Share
                    </div>
                    <div onClick={copyLinkUrl} className="cpyAlbum">
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ color: "#ffffff" }}
                      />
                      Copy Link
                    </div>
                    <Link to={`${getWhatsappShareUrl(shareUrl)}`}>
                      <button className="shareWp">
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ color: "#ffffff" }}
                        />
                        Share
                      </button>
                    </Link>
                  </div>
                </Popup>
                <div className="outerFlipDiv" onLoad={updateDimensions}>
                  <FontAwesomeIcon
                    className="leftFlip"
                    icon={faAngleRight}
                    flip="horizontal"
                    size="2xl"
                    style={{ color: "#ffffff", cursor: "pointer" }}
                    onClick={updateDimensions}
                  />
                  <Pageflip
                    albumData={albumData}
                    play={isPlaying}
                    rect={rect}
                    rotate={rotate}
                  />
                  <FontAwesomeIcon
                    className="rightFlip"
                    icon={faAngleRight}
                    size="2xl"
                    style={{ color: "#ffffff", cursor: "pointer" }}
                  />
                </div>
                {divHeight > 600 && (
                  <div className="instructions">
                    <div
                      style={{
                        maxWidth: "max-content",
                        backgroundColor: "rgba(255,255,255,0.1",
                        marginBottom: "1rem",
                        padding: "0.7rem 1rem",
                        borderRadius: "1rem",
                        fontSize: "initial",
                      }}
                    >
                      Use the arrows({" "}
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ color: "#ffffff" }}
                      />{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ color: "#ffffff" }}
                      />{" "}
                      )to navigate
                    </div>
                  </div>
                )}
                {linkCopied && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "1rem",
                      borderRadius: "0.5rem",
                      right: "1rem",
                      padding: "0.3rem 1.5rem",
                      zIndex: "1000",
                      backgroundColor: "rgba(255,255,255,0.1",
                    }}
                  >
                    <div
                      id="liveToast"
                      className="toast hide"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                    >
                      <div className="toast-body" style={{ color: "white" }}>
                        Link Copied
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "inherit",
                  padding: "1rem",
                  textAlign: "center",
                }}
              >
                Rotate your phone to landscape mode to view your album.
              </div>
            )}
          </div>
          <div
            className={`float ${isOpen ? "open" : ""}`}
            onClick={toggleDrawer}
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              style={{ color: "#ffffff" }}
              size="lg"
            />
          </div>
          <div
            className={`playButtonClass transition ${
              isOpen ? "playTrans" : ""
            }`}
            ref={playRef}
          >
            <FontAwesomeIcon
              onClick={() => {
                setIsOpen(false);
                setIsPlaying((prev) => !prev);
                goFull.current = `${isPlaying}`;
              }}
              icon={isPlaying ? faPause : faPlay}
              size="lg"
              style={{ color: "#ffffff" }}
              ref={playRef}
            />
          </div>
          <div className={`transition ${isOpen ? "shareTrans" : ""}`}>
            <FontAwesomeIcon
              onClick={ShareCall}
              icon={faShareNodes}
              size="lg"
              style={{ color: "#ffffff" }}
            />
          </div>
        </>
      ) : (
        <div className="fullpage-overlay">{globalLoader()}</div>
      )}
    </>
  );
};

export default AlbumView;