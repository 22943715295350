import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import "./Pageflip.css";

const Pageflip = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [imgClassDiv, setImgClassDiv] = useState("imgClass");
  const [fold, setFold] = useState(false);
  var rightTurn = document.getElementsByClassName("rightFlip");
  var leftTurn = document.getElementsByClassName("leftFlip");
  const book = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = props.albumData.data[0].url;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, [currentPage]);
  var totalNum = props.albumData.data.length;
  const onFlip = (e) => {
    setCurrentPage(e.data);
    e.data === totalNum - 1 && setImgClassDiv("imgClass");
    e.data === 0 && setImgClassDiv("imgClass");
  };
  const onChangeState = (e) => {
    book.current.pageFlip().getCurrentPageIndex() > 0 && setFold(true);
    if (e.data && e.data === "flipping") {
      leftTurn[0].style.color = "white";
      if (book.current.pageFlip().getCurrentPageIndex() === totalNum - 3)
        rightTurn[0].style.color = "black";
      if (book.current.pageFlip().getCurrentPageIndex() !== totalNum - 3)
        rightTurn[0].style.color = "white";
      setImgClassDiv("");
    }
    if (e.data && e.data === "user_fold") {
      setImgClassDiv("");
    }
    if (e.data && e.data === "fold_corner") {
      setImgClassDiv("");
    }
    if (
      e.data !== "fold_corner" &&
      e.data !== "flipping" &&
      e.data !== "user_fold" &&
      (book.current.pageFlip().getCurrentPageIndex() === 0 ||
        book.current.pageFlip().getCurrentPageIndex() === totalNum - 1)
    ) {
      setImgClassDiv("imgClass");
    }
    if (book.current.pageFlip().getCurrentPageIndex() === totalNum - 1) {
      rightTurn[0].style.color = "black";
    } else {
      rightTurn[0].style.color = "white";
    }
    if (book.current.pageFlip().getCurrentPageIndex() === 0) {
      leftTurn[0].style.color = "black";
    } else {
      leftTurn[0].style.color = "white";
    }
  };

  const handleLeftClick = () => {
    book.current.pageFlip().flipPrev();
  };
  useEffect(() => {
    leftTurn[0].style.color = "black";
    if (leftTurn) {
      leftTurn[0].addEventListener("click", handleLeftClick);
    }
    return () => {
      leftTurn[0] && leftTurn[0].removeEventListener("click", handleLeftClick);
    };
  }, []);
  const handleRightClick = () => {
    leftTurn[0].style.display = "block";
    book.current.pageFlip().flipNext();
  };
  useEffect(() => {
    if (rightTurn) {
      rightTurn[0].addEventListener("click", handleRightClick);
    }
    return () => {
      rightTurn[0] &&
        rightTurn[0].removeEventListener("click", handleRightClick);
    };
  }, []);

  const centerAnimation = () => {
    if (currentPage === 0) {
      setImgClassDiv("");
    }
    if (currentPage === totalNum - 1) {
      setImgClassDiv("");
    }
  };
  var timeoutId = useRef(null);
  useEffect(() => {
    const handleKeySide = (e) => {
      if (e.key === "ArrowRight") {
        book.current.pageFlip().getCurrentPageIndex() < totalNum - 3 &&
          setImgClassDiv("");
        book.current.pageFlip().flipNext();
      } else if (e.key === "ArrowLeft") {
        book.current.pageFlip().getCurrentPageIndex() > 0 && setImgClassDiv("");
        book.current.pageFlip().flipPrev();
      }
    };
    document.addEventListener("keydown", handleKeySide);
    return () => {
      document.removeEventListener("keydown", handleKeySide);
    };
  }, []);

  useEffect(() => {
    if (props.play && currentPage < totalNum) {
      setImgClassDiv("");
      book.current.pageFlip().flipNext();
      timeoutId.current = setInterval(() => {
        setImgClassDiv("");
        book.current.pageFlip().flipNext();
        book.current.pageFlip().getCurrentPageIndex() === totalNum - 1 &&
          setImgClassDiv("imgClass");
      }, 5000);
    } else {
      clearInterval(timeoutId.current);
    }

    return () => {
      clearInterval(timeoutId.current);
    };
  }, [props.play]);
  return (
    <>
      <HTMLFlipBook
        onFlip={onFlip}
        ref={book}
        showCover={true}
        size="stretch"
        height={`${dimensions.height}`}
        width={`${dimensions.width}`}
        flippingTime={700}
        onChangeState={onChangeState}
        showPageCorners={fold}
      >
        {dimensions.width > 0 && props.albumData.data.map((e) => {
          return (
            <img
              onClick={centerAnimation}
              key={e.identifier}
              className={`page ${imgClassDiv}`}
              src={e.url}
            />
          );
        })}
      </HTMLFlipBook>
    </>
  );
};

export default Pageflip;
